<template>
  <div class="wrap">
    <p class="title">无人机管理</p>
    <!-- 操作 -->
    <div class="action">
      <a-button size="small" @click="showThresholdModal">阈值设置</a-button>
      <a-button size="small" @click="returnBack">返回无人机任务列表</a-button>
      <a-button type="primary" size="small" @click="showAddEditModal">新增无人机</a-button>
    </div>
    <!-- 表格 -->
    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :pagination="{ pageSize: pageSize, current: curPage, total: total }"
      :row-key="record => record.id"
      @change="handlePageChange"
    >
      <template #idx="row">
        {{ (curPage - 1) * pageSize + row.index + 1 }}
      </template>
      <template #action="row">
        <div class="action">
          <span class="edit" @click="showAddEditModal(row)">编辑</span>
          <span class="del" @click="del(row)">删除</span>
        </div>
      </template>
    </a-table>
  </div>
  <add-edit-uav-modal ref="AddEditUavRef" @addOrUpdateSuccess="search"></add-edit-uav-modal>

  <a-modal v-model:visible="thresholdVisible"  @ok="handleOk">
      <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="无人机车流阈值">
          <a-input v-model:value.trim="peopleThreshold" placeholder="请输入无人机车流阈值" allowClear></a-input>
        </a-form-item>
        <a-form-item label="无人机人流阈值">
          <a-input v-model:value.trim="carThreshold" placeholder="请输入无人机人流阈值" allowClear></a-input>
        </a-form-item>
      </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, ref, toRefs, createVNode, reactive, onMounted } from 'vue';
import api from '@/services';
import { useRouter } from 'vue-router';
import { Modal, message } from 'ant-design-vue';
import AddEditUavModal from './AddEditUavModal';

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    align: 'center',
    width: 80,
    slots: {
      customRender: 'idx'
    }
  },
  {
    title: '无人机名称',
    dataIndex: 'uavName',
    align: 'center',
    ellipsis: true
  },
  {
    title: '播流地址',
    dataIndex: 'liveAddress',
    align: 'center'
  },
  {
    title: '新增时间',
    dataIndex: 'createTime',
    align: 'center'
  },
  {
    title: '已执行任务',
    dataIndex: 'taskOver',
    align: 'center'
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    width: 150,
    align: 'center',
    slots: {
      customRender: 'action'
    }
  }
];
export default defineComponent({
  components: {
    AddEditUavModal
  },
  setup() {
    onMounted(() => {
      getUavManageList();
    });

    const router = useRouter();

    const AddEditUavRef = ref();
    const isEdit = ref(false);

    const videoUrl = ref('');
    const visible = ref(false);

    // table 相关变量
    const tableState = reactive({
      pageSize: 5,
      curPage: 1,
      total: 0,
      dataSource: [],
      columns
    });

    // 条件筛选表单
    const formState = reactive({
      taskName: '',
      flyer: '',
      rangeDate: []
    });

    // 获取无人机列表 table data
    const getUavManageList = async () => {
      const params = {
        curPage: tableState.curPage,
        pageSize: tableState.pageSize
      };
      delete params.rangeDate;

      const { data, success } = await api.getUavManageList(params);
      if (success) {
        tableState.dataSource = data.records;
        tableState.total = data.total;
      }
    };

    // 显示 新增 / 编辑 弹窗
    const showAddEditModal = row => {
      let rowData;
      if (row.record) {
        isEdit.value = true;
        rowData = row.record;
      } else {
        isEdit.value = false;
      }
      AddEditUavRef.value.showModal(isEdit.value, rowData);
    };

    // 删除
    const del = row => {
      Modal.confirm({
        title: '',
        centered: true,
        content: createVNode(
          'div',
          {
            style: 'text-align: center;font-size: 18px;font-weight: bold;'
          },
          `确认要删除该无人机吗？`
        ),

        async onOk() {
          let { success } = await api.delUavById(row.record.id);
          if (success) {
            message.success('删除成功！');
            search();
          }
        }
      });
    };

    const handleCancel = () => {
      visible.value = false;
    };

    // 查看详情
    const showDetailModal = () => {};

    const handlePageChange = page => {
      tableState.curPage = page.current;
      getUavManageList();
    };

    const search = () => {
      tableState.curPage = 1;
      getUavManageList();
    };

    const reset = () => {
      Object.keys(formState).map(key => (formState[key] = ''));
      search();
    };

    // 返回无人机任务列表
    const returnBack = () => {
      router.push('/admin/machine-manage/uav-task-list');
    };

    const wrapperCol = reactive({ span: 14 });
    const labelCol = reactive({ span: 6, offset: 1 });
    const thresholdVisible = ref(false);
    const thresholdFormState = reactive({
      peopleThreshold: '',
      carThreshold: ''
    });
    const showThresholdModal = async () => {
      thresholdVisible.value = true
      const { data } = await api.getThreshold();
      Object.keys(thresholdFormState)?.map(key => {
        thresholdFormState[key] = data[key];
      });
    }
    const handleOk = async () => {
      let loading = true
      Object.keys(thresholdFormState).map(key => {
        if(!thresholdFormState[key]) {
          loading = false
        }
      });
      if(!loading) {
        message.error('请输入完整')
        return false 
      }
      const { data, success } = await api.setThreshold({...thresholdFormState});
      if (success) {
        message.success(`编辑成功！`);
      }
      thresholdVisible.value = false
    }

    return {
      AddEditUavRef,
      showAddEditModal,
      isEdit,
      visible,
      videoUrl,
      ...toRefs(formState),
      getUavManageList,
      handlePageChange,
      search,
      reset,
      del,
      handleCancel,
      showDetailModal,
      returnBack,
      ...toRefs(tableState),

      ...toRefs(thresholdFormState),
      showThresholdModal,
      thresholdVisible,
      labelCol,
      wrapperCol,
      handleOk
    };
  }
});
</script>

<style lang="less" scoped>
.wrap {
  padding: 15px 20px;
  flex-wrap: wrap;
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  .action {
    display: flex;
    justify-content: flex-end;
    .ant-btn {
      margin-right: 20px;
    }
  }
  :deep(.ant-form) {
    display: flex;
    justify-content: center;
    margin: 15px 0;
    .btn-group {
      flex: 1;
      .ant-btn {
        margin-left: 20px;
      }
    }
  }
  :deep(.ant-table-wrapper) {
    margin-top: 15px;
    .ant-table {
      .avatar {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
      .action > span {
        margin-right: 14px;
        display: inline-block;
        padding: 0px 10px;
        border-radius: 3px;
        font-size: 12px;
        height: 25px;
        line-height: 25px;
      }

      .action {
        display: flex;
        span {
          cursor: pointer;
          color: #fff;
        }
        .edit {
          background-color: #007fff;
        }
        .del {
          background-color: #d71345;
        }
      }
    }
  }
}
</style>
